import styled from "styled-components"

export const DescriptionModuleWrapper = styled.div`
    padding-top: 56px;
    background-color: #FFFFFF;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-left: 14px;
    padding-right: 25px;
    position: relative;
    box-shadow: 0px 0px 14px 0px #E0E0E0;
`
export const DescriptionModuleTitle = styled.h3`
    margin: 0;
    padding-top: 17px;
    padding-bottom: 12px;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 400;
    line-height: 25.2px;
    color: #050505;
`
export const DescriptionModuleText = styled.p`
    margin: 0;
    padding-bottom: 14px;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #050505;
`