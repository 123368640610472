import {renderToStaticMarkup} from 'react-dom/server'
import ReactPlayer from "react-player";
import { marked } from "marked"
import { useEffect, useState, useLayoutEffect } from "react"
import { ButtonMainBig } from "../../../Constants/ButtonMainBig"
import { Header, HeaderText, HeaderClose, LessonWrapper, LessonContent, TestWrapper, TestElement, TestElementPass, HomeworkElem, HomeworkTitle, Input,
  HomeworkDescription, HomeWorkSended, IconSendedHomeWork, TextSendedHomeWork, ProgressBar, InputErrorText, ProgressBarWrapperSlide, ProgressBarSlide, TextComment } from "./styled"
import './styles.css';
import CloseIcon from "../../../Images/cross.svg";
import CheckMark from "../../../Images/checkmark.svg";
import CheckMarkError from "../../../Images/checkmarkerror.svg";
import CheckMarkSuccess from "../../../Images/checkmarksuccess.svg"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TailSpin } from "react-loader-spinner";
interface IProps {
    preloadImages: any
    passlesson: any,
    close: any,
    lesson: {
        attributes: any,
        id: number
    },
    dataUser: any,
    module: number,
    passes: number[],
    setiswait: any,
    homeworkerror: any[],
    homeworkstatus: string,
    rerend: any,
    homework: any
}

interface ISelect {
  isChose: boolean
}

const Lesson = ({preloadImages, passlesson, close, lesson, dataUser, module, passes, setiswait, homeworkerror, homeworkstatus, rerend, homework}: IProps) => {
    const navigate = useNavigate();
    const [html, setHtml] = useState<string>('');
    const [rerender, setRerender] = useState(false);
    const [isPassTest, setPassTest] = useState(false);
    const [inputArray, setInputArray] = useState<any[]>([])
    const [isSelect, setSelect] = useState([{isChose: false}]);
    const [actualModule, setActualModule] = useState(module);
    const [isPassHomework, setIsPassHomework] = useState(false);
    const [slide, setSlide] = useState(0);
    const [arrSlides, setArrSlides] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [comment, setComment] = useState('');

    const [animatedIndex, setAnimatedIndex] = useState(0);
    const handleClose = () => {
        close({attributes: null, id: 0});
    }
    console.log(homework);
    
    useEffect(() => {
      if(!localStorage.getItem('user_token') || !localStorage.getItem('user_id')) {
        navigate('/authorization')
      }
      const handleBackButton = (event: any) => {
        event.preventDefault(); // Отменить стандартное поведение браузера
        handleClose();
      };

      window.addEventListener('popstate', handleBackButton);
    
      return () => {
        window.removeEventListener('popstate', handleBackButton);
    
        navigate('/contents');
      };
  }, []);

    useLayoutEffect(() => {
        rerend();
    }, []);

    useEffect(() => { // timeout for animation for sidebar
      if (animatedIndex < dataUser.data[actualModule - 1].attributes.lessons.data.length) {
        const timer = setTimeout(() => {
          setAnimatedIndex(animatedIndex + 1);
        }, 125);
  
        return () => clearTimeout(timer);
      }
    }, [animatedIndex, dataUser, actualModule]);
    
    const handlePassHomeWork = async (lesson: any) => {
      setIsPassHomework(true);
      if(dataUser && passes) {     
          const tasks: any[] = [];
          inputArray.map((item, index) => {
            if (item.value !== '') {
              const task = {
                name: lesson.attributes.component[index].title,
                value: item.value,
                pass: false,
                id: lesson.attributes.component[index].data_name
              }
              tasks.push(task);
            }
          })
          
          const data = {
                tasks: tasks,
                lesson: lesson.id,
                module,
                users_permissions_user: Number(localStorage.getItem('user_id')),
                pass: "wait"
            }
          
          await axios.post('https://api.academyblogger.site/api/home-tasks', 
            {data},
            {headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('user_token'),
            'Content-Type': 'application/json'
        }});
        setiswait();
        setIsPassHomework(false);
      }
    }

    const handlePassLesson = async (lesson: any) => {      
 
      if (slide <= arrSlides.length-2) {
        setSlide(prevSlide => prevSlide + 1)
      }

      else {
        if(dataUser && passes) {
          setActualModule(module);
          if (lesson.attributes.type === "input-test" && !passes.includes(lesson.id)) {          
            
            const tasks: any[] = [];
            inputArray.map((item, index) => {
              const task = {
                name: lesson.attributes.component[index].title,
                value: item
              }
              tasks.push(task);
            })
            const obj = {
              data: {
                  tasks: tasks,
                  lesson: lesson.id,
                  pass: "wait"
              }
            }

            await axios.post('https://api.academyblogger.site/api/home-tasks', {
              obj
          }, {headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('user_token')
          }});
          }

          else {
            let nextLesson = null;

            if (passes.includes(lesson.id)) {
              const position = dataUser.data[module-1].attributes.lessons.data.findIndex((item: any) => item.id === lesson.id);
              nextLesson = dataUser.data[module-1].attributes.lessons.data[position+1];
              setSlide(0);
            }
            else {
              setIsLoading(true);
              setSlide(0);
              setTimeout(() => {
                setIsLoading(false);
              }, 1000)
            }
            passlesson(lesson, nextLesson);
          }
        }
        setPassTest(false);
        setArrSlides([]);
        window.scrollTo({
          top: 0
        });
      }
    }
    
    const handleChangeHomeWorkInput = (index: number, event: any, item: any) => {
      
      const newArr = [...inputArray];
      newArr[index].value = event.target.value;
      setInputArray(newArr);
    }

    useEffect(() => {
      const arrInput: any[] = [];
        lesson.attributes.component.map((item: any) => {
          arrInput.push({
            value: '',
            id: item.data_name
          });
        });

      setInputArray(arrInput);
    }, [dataUser, lesson, homeworkerror])

    useEffect(() => {
      const select: ISelect[] = lesson.attributes.component.map((item: any) => {
        return { isChose: false };
      });

      setSelect(select);
    }, [lesson])

    const handleImageLoad = () => {
      rerend();
      setRerender(true);
    };
    
    useEffect(() => {

      if (homework) {
        const reversedArray = homework.data.slice().reverse();        
        const text = reversedArray.find((item: any) => {
          if (item.attributes.lesson.data.id === lesson.id) {            
            return item;
          }
        });
       
        if (text) {
          setComment(text.attributes.comment)
        }
      }
      
      if (lesson.attributes.text && slide <= arrSlides.length) {
        
        const markdownText = lesson.attributes.text;
        const slideText: string[] = markdownText.split('<!slide>') 

        setArrSlides(slideText);     
        const text: string = slideText[slide];

        const renderMarkdownToHtml = async (text: string) => {
          const renderer = new marked.Renderer();

          renderer.heading = (text: string, level: number, raw: string) => {
            switch (level) {
              case 1:
                return `<h${level} class="heading1">${text}</h${level}>`;
              case 2:
                return `<h${level} class="heading2">${text}</h${level}>`;
              case 3:
                return `<h${level} class="heading3">${text}</h${level}>`;
              case 4:
                return `<h${level} class="heading4">${text}</h${level}>`;
              case 5:
                return `<h${level} class="heading5">${text}</h${level}>`;
              case 6:
                return `<h${level} class="heading6">${text}</h${level}>`;
              default:
                return `<h${level}>${text}</h${level}>`;
              };
          };
          renderer.listitem = (text, task) => {
            const updateText = text.replace(/<img[^>]*>/g, '');
            const regex = /<img[^>]*src="([^"]*)"[^>]*>/;
            const match = text.match(regex);
            let imageStyle = ''
            if (match && match[1]) {
              const srcValue = match[1];
              imageStyle = `background-image: url(${srcValue});`;
              return `<li><span style="${imageStyle}" class="custom-marker"></span> ${updateText}</li>`;
            } 

            else {
              return `<li><span class="mark">&#8226</span>${updateText}</li>`;
            }
          };
          renderer.paragraph = (text) => {
            const regex = /"([^"]+)"/;
            const match = text.match(regex);
            if (text.includes('<img')) {

              return `${text}`
            }

            if (match) {

              const player = <ReactPlayer width="100%" height="247px" url={match[1]} controls={true} />;

              const markup = renderToStaticMarkup(player);
              
              return markup;
            }
            
            return `<p class="text">${text}</p>`
          }
          renderer.image = (image) => {
            
            if (preloadImages && preloadImages[image]) {
              const regex = /\?.+/;
              return `<img class=${regex.test(image) ? 'imageRes' : 'imageCurr'} src="${preloadImages[image].src}" alt="${text}" />`; 
            }
            else {
              const imgElement = new Image();
              imgElement.onload = handleImageLoad;
              imgElement.src = image;
              return imgElement.outerHTML;
            }
          }

          const htmlString = await marked(text, {renderer});
          setHtml(htmlString);
        };
        renderMarkdownToHtml(text);
      }
      else {
        setHtml('');
      }
      window.scrollTo({
        top: 0
      });
    }, [lesson, dataUser, homework, rerender, slide]);

    const handleSelect = (index: number) => {
      const updatedIsSelect = [...isSelect];
      updatedIsSelect[index].isChose ? updatedIsSelect[index].isChose = false : updatedIsSelect[index].isChose = true;
      setSelect(updatedIsSelect);
    }

    const handlePassTest = () => {
      setPassTest(true);
    }
   const currHomeworkStatus = homework.data.find((item: any) => {
      if (item.attributes.lesson.data.id === lesson.id && item.attributes.pass === 'success') {
        return item;
      }
   });
   
  let isDisabled = false;

  if (homeworkerror.length > 0) {

    inputArray.find((item, index) => {

      const isPass = homeworkerror.find((el) => el.id === item.id);
      
      if (isPass && isPass.pass === false && (item.value.length < 20 || item.value.length > 1000)) {
        isDisabled = true; 
      }
    })
  }
  else {
    inputArray.find((item) => {
      if (item.value.length < 20 || item.value.length > 1000) {
        isDisabled = true;
      }
    });
  }
  console.log(comment);
  
    return (
        <LessonWrapper>
          {!isLoading ?
            <>
            <Header>
                <HeaderClose onClick={handleClose} src={CloseIcon}/>
                <HeaderText>Curso de blogs</HeaderText>
            </Header>
            <ProgressBar className='progressBar'>       
                {arrSlides.map((item: any, index: number) => { 
                  return (
                    <ProgressBarWrapperSlide>
                      <ProgressBarSlide prevSlide={(index < slide) ? (100/arrSlides.length)*slide-(100/arrSlides.length)  : 0} slideScroll={(index < slide) ? (100/arrSlides.length)*slide : 0} className={`${index < slide ? 'fillSlide' : ''}`}></ProgressBarSlide>
                    </ProgressBarWrapperSlide>
                  )
                })}
            </ProgressBar>
            <LessonContent homeworkstatus={homeworkstatus} lessontype={lesson.attributes.type}>

                <div className="content" dangerouslySetInnerHTML={{ __html: html }}/>
                {lesson.attributes.type === "input-test" && (homeworkerror.length === 0 || homeworkstatus === 'wait') ? 
                  lesson.attributes.component.map((item: any, index: number) => {
                    
                    if (lesson.attributes.component.length === inputArray.length) {

                      return (
                        <HomeworkElem key={index}>
                            <HomeworkTitle>{item.title}</HomeworkTitle>
                            <HomeworkDescription>{item.description}</HomeworkDescription>
                            {homeworkstatus !== 'wait' && !passes.includes(lesson.id) ? <Input iserror={inputArray[index].value} value={inputArray[index].value} onChange={(e) => handleChangeHomeWorkInput(index, e, item)}></Input> 
                            : ""}
                            {inputArray[index].value && inputArray[index].value.length < 20 ? 
                            <InputErrorText>La respuesta debe tener al menos 20 caracteres</InputErrorText> : ''}
                            {inputArray[index].value && inputArray[index].value.length > 1000 ? 
                            <InputErrorText>La respuesta debe tener como máximo 1000 caracteres</InputErrorText> : ''}  
                        </HomeworkElem>
                      )
                    }
                    
                  })
                : lesson.attributes.type === "input-test" && homeworkerror.length !== 0 ? 
                    lesson.attributes.component.map((item: any, index: number) => {
                      const error = homeworkerror.find((elem: any) => {
                        if (elem.id === item.data_name && elem.pass === false) {
                          return item;
                        }
                      })
                      
                      if (error && !passes.includes(lesson.id)) {

                        return (
                          <HomeworkElem key={index}>
                              <HomeworkTitle>{item.title}</HomeworkTitle>
                              <HomeworkDescription>{item.description}</HomeworkDescription>
                              <Input iserror={inputArray[index] ? inputArray[index].value : ''} value={inputArray[index] ? inputArray[index].value : ''} onChange={(e) => handleChangeHomeWorkInput(index, e, item)}></Input>
                              {inputArray[index] && inputArray[index].value.length < 20 ? 
                              <InputErrorText>La respuesta debe tener al menos 20 caracteres</InputErrorText> : ''}
                              {inputArray[index] && inputArray[index].value && inputArray[index].value.length > 1000 ? 
                              <InputErrorText>La respuesta debe tener como máximo 1000 caracteres</InputErrorText> : ''}  
                          </HomeworkElem>
                        )
                      }
                      else {
                        return (
                          <HomeworkElem key={index}>
                              <HomeworkTitle>{item.title}</HomeworkTitle>
                              <HomeworkDescription>{item.description}</HomeworkDescription>
                          </HomeworkElem>
                        )
                      }
                    })
                : ''}
                {lesson.attributes.type === "test" ?
                (isPassTest ? 
                <TestWrapper>
                  {lesson.attributes.component.map((test: any, index: number) => {

                    const correctChose = isSelect[index].isChose === test.right;

                    const elem = isSelect[index]
                    
                    if (elem) {
                      return <TestElementPass iscorrect={test.right} iscorrectchose={correctChose} ischose={isSelect[index].isChose} key={index}>{test.field}</TestElementPass>;
                    }
                  })}
                </TestWrapper>
                :
                <TestWrapper>
                  {lesson.attributes.component.map((test: any, index: number) => {
                    const elem = isSelect[index]
                    
                    if (elem) {
                      return <TestElement select={isSelect[index]} onClick={() => handleSelect(index)} key={index}>{test.field}</TestElement>;
                    }
                  })}
                </TestWrapper>)
                : ''}

              {comment && <TextComment>Comentario: {comment}</TextComment>}
            </LessonContent>
            {lesson.attributes.type === 'input-test' && (homeworkstatus === 'wait' || homeworkstatus === 'error') && !currHomeworkStatus ?
                                         
              (homeworkstatus === 'wait' ? 
              <HomeWorkSended>
                <IconSendedHomeWork onLoad={handleImageLoad} src={CheckMark}></IconSendedHomeWork>
                <TextSendedHomeWork>Recibimos tu tarea. Después de que la revisemos, puedes continuar con el siguiente módulo. Revisar esta tarea puede tomar hasta 24 horas. Ahora, puedes consolidar tu conocimiento haciendo las lecciones una vez más</TextSendedHomeWork>
              </HomeWorkSended>
              :
               <HomeWorkSended>
               <IconSendedHomeWork onLoad={handleImageLoad}  src={CheckMarkError}></IconSendedHomeWork>
               <TextSendedHomeWork>Tu tarea contiene errores. Corrige el error para continuar aprendiendo</TextSendedHomeWork>
             </HomeWorkSended>)
             :
             ''
            }
            {lesson.attributes.type === 'input-test' && currHomeworkStatus ?
            <HomeWorkSended>
              <IconSendedHomeWork onLoad={handleImageLoad} src={CheckMarkSuccess}></IconSendedHomeWork>
              <TextSendedHomeWork>¡Felicidades! No cometiste errores en tu tarea. Ahora, puedes continuar con el siguiente módulo.</TextSendedHomeWork>
            </HomeWorkSended>
            : ''
            }
            {lesson.attributes.type === 'test' && !isPassTest ? <ButtonMainBig disabled={isSelect.find((item) => item.isChose === true) ? false : true} onClick={handlePassTest}>Siguiente</ButtonMainBig> 
            :
            (lesson.attributes.type === 'input-test' ? (
            homeworkstatus !== 'wait' && !passes.includes(lesson.id) ? <ButtonMainBig disabled={Boolean(isDisabled) || isPassHomework} onClick={() => handlePassHomeWork(lesson)}>Siguiente</ButtonMainBig>
            : (passes.includes(lesson.id) ? <ButtonMainBig onClick={() => handlePassLesson(lesson)}>Siguiente</ButtonMainBig> : <ButtonMainBig onClick={() => handleClose()}>Siguiente</ButtonMainBig>)
            ) 
            : 
            <ButtonMainBig style={lesson.attributes.type === 'test' ? { backgroundColor: "#1976D2" } : {}} onClick={() => handlePassLesson(lesson)}>Siguiente</ButtonMainBig>)
            }
            </>
            : 
            <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10}}>
              <TailSpin visible={true}
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""/>
            </div>}
        </LessonWrapper>
    )
}

export default Lesson;