import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {AuthApi} from './APIs/auth.api';
import { ModulesApi } from './APIs/modules.api';
import { PassLessonsApi } from './APIs/lessonsPasses.api';
import { HomeWorkApi } from './APIs/homework.api';
import {errorsReducer} from './Slices/errors.slice';
import {authReducer} from './Slices/auth.slice';
import { modulesReducer } from './Slices/modules.slice';
import { passesReducer } from './Slices/lessonsPasses.slice';
import { homeWorkReducer } from './Slices/homeWork.slice';

const rootReducer = combineReducers({
  [AuthApi.reducerPath]: AuthApi.reducer,
  [ModulesApi.reducerPath]: ModulesApi.reducer,
  [PassLessonsApi.reducerPath]: PassLessonsApi.reducer,
  [HomeWorkApi.reducerPath]: HomeWorkApi.reducer,
  errorsSlice: errorsReducer,
  authSlice: authReducer,
  modulesSlice: modulesReducer,
  passesSlice: passesReducer,
  homeWorkSlice: homeWorkReducer
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(AuthApi.middleware, ModulesApi.middleware, PassLessonsApi.middleware , HomeWorkApi.middleware)
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];