import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface IHomeWorkState {
    homeWork: any;
}

const initialState: IHomeWorkState = {
    homeWork: null
};

const homeWorkSlice = createSlice({
  name: 'homeWork',
  initialState,
  reducers: {
    setData: (state, action :PayloadAction<IHomeWorkState>) => {
      state.homeWork = action.payload;
    }
  }
});

export const homeWorkActions = homeWorkSlice.actions;
export const homeWorkReducer = homeWorkSlice.reducer;