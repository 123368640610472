import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {errorsActions} from '../Slices/errors.slice';
import { homeWorkActions } from '../Slices/homeWork.slice';
interface IHomeWorkResponse {
  homeWork :any;
}

const {addError} = errorsActions;
const {setData} = homeWorkActions;

export const HomeWorkApi = createApi({
  reducerPath: 'HomeWorkApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.academyblogger.site',
    prepareHeaders: headers => {
        const token :string | null = localStorage.getItem('user_token');
  
        if (token) headers.set('Authorization', `Bearer ${token}`);
      }
  }),
  endpoints: (build) => ({
    homeWork: build.query<IHomeWorkResponse, void>({
      query: () => ({
        url: 'api/home-tasks',
        method: 'get',
        params: {
            populate: 'users_permissions_user, lesson',
            "filters[users_permissions_user][id][$eq]": localStorage.getItem('user_id')

        }
      }),
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          const response = await queryFulfilled;
          
          if (!response) {
            throw new Error(response);
          }

          dispatch(setData(response.data));
        } catch (error :any) {
          localStorage.removeItem('user_token');
          localStorage.removeItem('user_id');
          dispatch(addError(error.error));
        }
      },
    })
  })
});

export const {useHomeWorkQuery} = HomeWorkApi;