import styled from "styled-components";

export const ButtonSecondaryBig = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 146.5px;
    border-radius: 12px;
    border: 1px solid #7E57C2;
    background-color: rgba(0, 0, 0, 0);
    color: #050505;
    font-size: 14px;
    line-height: 16.41px;
    font-family: "Roboto";
    font-weight: 400; 
    width: 328px;
    outline: none;
    text-align: center;
    cursor: pointer;
    z-index: 1;
`