import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HeaderWrapper, SidebarIcon, Title, PageWrapper, Sidebar, CloseSideBar, AdditionalButtonsWrapper, 
SupportButton, SidebarWrapper, Gradient, FirstColor, SecondColor, Main, Wrapper, SupportWrapper, LinkWrapper } from "./styled";
import { ButtonSecondaryMedium } from "../../Constants/ButtonSecondaryMedium";
import SidebarModules from "./SidebarModules/SidebarModules";
import DescriptionModules from "./DescriptionModules/DescriptionModules";
import LessonsList from "./LessonsList/LessonsList";
import Lesson from "./Lesson/Lesson";
import OpenIcon from "../../Images/sidebar.svg";
import CloseIcon from "../../Images/close.svg";
import { useAppSelector } from "../../Hooks/redux/useAppSelector";
import { useModulesQuery } from "../../Store/APIs/modules.api";
import { usePassesQuery } from "../../Store/APIs/lessonsPasses.api";
import { useHomeWorkQuery } from "../../Store/APIs/homework.api";
import axios from "axios";
import './styles.css';
import { TailSpin } from "react-loader-spinner";

const ContentPage = () => {
    const navigate = useNavigate();

    useModulesQuery({populate: "lessons"});
    const {data, isLoading, isError, refetch} = usePassesQuery();
    const {
        data: homeWorkData,
        isLoading: homeWorkIsLoading,
        isError: homeWorkIsError,
        refetch: homeWorkRefetch
    } = useHomeWorkQuery();

    const dataUser = useAppSelector(state => state.modulesSlice.modules);
    const passesLessons = useAppSelector(state => state.passesSlice.passes);
    const homeWork = useAppSelector(state => state.homeWorkSlice.homeWork);
    const elementHigh = useRef<any>(null);
    const [actualModule, setActualModule] = useState(0);
    const [chosenModule, setChosenModule] = useState(0);
    const [passesState, setPassesState] = useState([0]);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [openLesson, setOpenLesson] = useState({attributes: null, id: 0});
    const [actualLesson, setActualLesson] = useState<any>({attributes: null, id: -1});
    const [errorHomeWork, setErrorHomeWork] = useState<any>([]);
    const [errorHomeWorkID, setErrorHomeWorkID] = useState<number | null>(null)
    const [homeWorkStatus, setHomeWorkStatus] = useState('');
    const [rerend, setRerend] = useState(false);
    const [preloadedImages, setPreloadedImages] = useState<any | null>(null);
    const [loading, setLoading] = useState(true);
    const [isFinished, setIsFinished] = useState(false);

    const module: number[] = [];
    const passes: number[] = [];
    const [isPageLoaded, setIsPageLoaded] = useState(false);
  
    useEffect(() => {
        if(!localStorage.getItem('user_token') || !localStorage.getItem('user_id')) {
            navigate('/authorization')
        }
      setIsPageLoaded(true);
    }, []);
    
    useEffect(() => {

        if (actualLesson.id && actualLesson.attributes?.type === 'input-test' && homeWork && homeWork.data.length !== 0) {

            if (homeWork.data[homeWork.data.length-1].attributes.pass === 'success' && homeWorkStatus !== 'success' && homeWork.data[homeWork.data.length-1].attributes.lesson.data.id === actualLesson.id) {
                let nextLesson = null;
                const comment = homeWork.data[homeWork.data.length-1].attributes.comment;
                if (passes.includes(actualLesson.id)) {
                  const position = dataUser.data[actualModule-1].attributes.lessons.data.findIndex((item: any) => item.id === actualLesson.id);
                  nextLesson = dataUser.data[actualModule-1].attributes.lessons.data[position+1];
                }
                handlePassLesson(actualLesson, nextLesson, comment);
                setHomeWorkStatus('success');
                setErrorHomeWorkID(null);   
                setErrorHomeWork([]);
            }

            if (homeWork.data[homeWork.data.length-1].attributes.pass  === 'error') {
                setHomeWorkStatus('error');
                const errorArr: any[] = [];
                for (let item of homeWork.data[homeWork.data.length-1].attributes.tasks) {
                    errorArr.push(item)
                }
                setErrorHomeWork(errorArr);
                setErrorHomeWorkID(homeWork.data[homeWork.data.length-1].attributes.lesson.data.id);
            }
            
            if (homeWork.data[homeWork.data.length-1].attributes.pass === 'wait') {
                
                setHomeWorkStatus('wait');
                setErrorHomeWorkID(null);
                setTimeout(() => {
                    homeWorkRefetch();
                }, 10000);
            }
        }
        
    }, [homeWorkStatus, homeWork, actualLesson])

    const imagesMap: any = {};
    const loadImages = async (links: any) => {
        const imagePromises = links.map((link: any) => {
            return new Promise((resolve, reject) => {
                const image = new Image();
                image.src = link;
                image.onload = () => {
                    imagesMap[link] = image;
                    resolve(image);
                };
                image.onerror = () => reject(new Error(`Failed to load image from link: ${link}`));
            });
        });

        await Promise.all(imagePromises);
        setPreloadedImages(imagesMap)
    };
    
    useEffect(() => {
        if (dataUser) {
            for (const item of dataUser.data) {
                for (const lesson of item.attributes.lessons.data) {
                    const regex = /http[s]?:\/\/[^\s)]+/g;
                    let links = null
                    if (lesson.attributes.text) {
                        links = lesson.attributes.text.match(regex);
                    }
    
                    if (links) {
                        loadImages(links);
                    }
                }
            }
        }
    }, [dataUser])

    useEffect(() => { // search actual module
        if (dataUser && passesLessons) {
            passesLessons.data.map((pass: any) => {
                passes.push(pass.attributes.lesson.data.id);
            })
            let lastModule = actualModule;            

            dataUser.data.map((item: any, index: number) => {
                item.attributes.lessons.data.map((lesson: any) => {                            
                    module.push(lesson.id);
                })

                const containsAllElements = module.every(element => passes.includes(element));
    
                
                if (containsAllElements && dataUser.data[lastModule].attributes.lessons.data.length !== 0) {
                    
                    lastModule = index + 1;
                }
            })
            
            setPassesState(passes);
            if (dataUser.data.length > lastModule) {                
                setActualModule(lastModule + 1);
                setChosenModule(lastModule + 1);
            }

            else {
                setActualModule(lastModule);
                setChosenModule(lastModule);
            }

            if (dataUser.data.length === lastModule) {
                setIsFinished(true);
            }
        }

    }, [dataUser, passesLessons])
    
    
    const [isOpen, setIsOpen] = useState(false);
    
    const handleOpenSidebar = () => {
        if (dataUser) {
            setIsPageLoaded(false);
            setIsOpen(true);
        }
    };

    const handleCloseSidebar = () => {
        setIsOpen(false);
    };

    const handleChoseModule = (value: number, m: any) => {
        
        setChosenModule(value);
        setIsOpenPopup(false);
        setIsOpen(false);
    };

    const handleOpenPopup = () => {
        setIsOpenPopup(true);
    };

    const handleOpenLesson = (lesson: any) => {
        setOpenLesson(lesson);
    };

    const handleCloseLesson = (lesson: any) => {
        setOpenLesson(lesson);
    };

    const handleSetIsWait = () => {
        homeWorkRefetch();
        setHomeWorkStatus('wait')
    }

    const handlePassLesson = async (lesson: any, nextLesson: any, comment: string) => {
        console.log(comment);
        
        if (nextLesson === undefined) {
            setOpenLesson({attributes: null, id: 0});

            
            if (chosenModule < dataUser.data.length) {
                const module = chosenModule+1;
                setChosenModule(module);
            }
            else {
                setChosenModule(chosenModule);
            }
        }

        if (nextLesson === null) {

            await axios.post('https://api.academyblogger.site/api/lessons-passes', {
                data: {
                    pass: true,
                    users_permissions_users: [Number(localStorage.getItem('user_id'))],
                    lesson: lesson.id,
                    limit: -1,
                    comment
                }
            }, {headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('user_token')
            }});

            const position = dataUser.data[actualModule-1].attributes.lessons.data.findIndex((item: any) => item.id === lesson.id);
            const next: any = dataUser.data[actualModule-1].attributes.lessons.data[position+1];
 
            refetch();
            setActualModule(0);
            setChosenModule(0);
            
            if (next) {
                setOpenLesson({attributes: next.attributes, id: next.id});
                setActualLesson(next);
            }
            else {
                setOpenLesson({attributes: null, id: 0});
            }
        }
        
        if (nextLesson && lesson) {
            setOpenLesson(nextLesson);
        }
    };

    const handleActualLesson = (result: any) => {
        setActualLesson(result);
    }

    const handleRerend = () => {
        setRerend(true);
    }

    const logout = () => {
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_token');
        navigate('/authorization')
        window.location.reload();
    };

    const handleSetLoading = (state: boolean) => {
        
        setLoading(state)
    }
    
    return(
        <Wrapper>
            <Gradient>
                <FirstColor/>
                <SecondColor/>
            </Gradient>
            <Main ref={elementHigh}>
                {openLesson.id ? 
                <Lesson preloadImages={preloadedImages} passlesson={handlePassLesson} close={handleCloseLesson} lesson={openLesson} dataUser={dataUser} module={chosenModule} passes={passesState} setiswait={handleSetIsWait} homeworkerror={errorHomeWork} homeworkstatus={homeWorkStatus} rerend={handleRerend}  homework={homeWork}/>
                :
                <PageWrapper open={isOpen}>
                    {isLoading === false ?
                    <> 
                        <HeaderWrapper>
                            <SidebarIcon onClick={handleOpenSidebar} src={OpenIcon}></SidebarIcon>
                            <Title>Curso de blogs</Title>
                        </HeaderWrapper>
                        <DescriptionModules actual={chosenModule} dataUser={dataUser}></DescriptionModules>
                        <LessonsList loading={handleSetLoading} module={chosenModule} dataUser={dataUser} passes={passesState} ispopup={isOpenPopup} setpopup={handleOpenPopup} choselesson={handleOpenLesson} setlesson={handleActualLesson} homeworkstatus={homeWorkStatus}></LessonsList>
                    </>
                    :
                    <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10}}>
                        <TailSpin visible={true}
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""/>
                    </div>}
                    
                </PageWrapper>
                }
                <Sidebar className={`sidebar ${isOpen ? 'open' : 'close'} ${isPageLoaded ? 'loaded' : ''}`}>
                    {isOpen && (
                    <>
                        <CloseSideBar onClick={handleCloseSidebar} src={CloseIcon}/>
                        <SidebarModules choseModule={handleChoseModule} dataUser={dataUser} last={actualModule} errorlessonid={errorHomeWorkID} passeslesson={passesLessons} isfinished={isFinished}></SidebarModules>
                        <AdditionalButtonsWrapper>
                            <ButtonSecondaryMedium onClick={logout}>Cerrar sesión</ButtonSecondaryMedium>
                            <SupportWrapper>
                                <SupportButton>Soporte</SupportButton>
                                <LinkWrapper>
                                    <a href={'https://t.me/AcademiaBlog_Soporte'}>
                                        <svg width="33" height="21" viewBox="0 0 33 21" fill="none" xmlns="https://www.w3.org/2000/svg">
                                        <rect width="33" height="21" rx="2" fill="black"/>
                                        <g clip-path="url(#clip0_857_174)">
                                        <path d="M16.5 21C22.299 21 27 16.299 27 10.5C27 4.70101 22.299 0 16.5 0C10.701 0 6 4.70101 6 10.5C6 16.299 10.701 21 16.5 21Z" fill="black"/>
                                        <path d="M9.91048 11.0135C11.1379 10.3374 12.5081 9.77312 13.7883 9.20595C15.9908 8.27696 18.202 7.36407 20.4355 6.51418C20.87 6.36937 21.6509 6.2278 21.7274 6.8718C21.6854 7.78337 21.5131 8.68961 21.3948 9.59585C21.0946 11.5887 20.7474 13.5748 20.4091 15.5612C20.2924 16.2227 19.4636 16.5653 18.9333 16.1419C17.6588 15.2809 16.3745 14.4285 15.1162 13.5475C14.7041 13.1287 15.0863 12.5273 15.4543 12.2282C16.504 11.1937 17.6173 10.3147 18.6122 9.22677C18.8805 8.57875 18.0876 9.12492 17.8261 9.29231C16.389 10.2826 14.9871 11.3334 13.4719 12.2038C12.698 12.6298 11.7959 12.2657 11.0223 12.0279C10.3287 11.7408 9.31233 11.4515 9.91039 11.0136L9.91048 11.0135Z" fill="white"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_857_174">
                                        <rect width="21" height="21" fill="white" transform="translate(6)"/>
                                        </clipPath>
                                        </defs>
                                        </svg>
                                    </a>
                                    <a href={'mailto:support@academyblogger.site'}>
                                        <svg width="33" height="21" viewBox="0 0 33 21" fill="none" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M10.0811 10.5L0 17.0867V3.91327L10.0811 10.5ZM22.9189 10.5L33 17.0867V3.91327L22.9189 10.5ZM21.446 11.4652L16.9387 14.4092C16.8059 14.494 16.6529 14.5385 16.5 14.5385C16.3471 14.5385 16.1941 14.494 16.0613 14.4092L11.554 11.4652L0.668049 18.5769L0.0362196 18.9888C0.233415 20.1277 1.22341 21 2.41463 21H30.5854C31.7766 21 32.7666 20.1317 32.9638 18.9888L32.3279 18.5769L21.446 11.4652ZM16.5 12.7656L32.3279 2.42308L32.9638 2.01115C32.7666 0.868269 31.7766 0 30.5854 0H2.41463C1.22341 0 0.233415 0.872308 0.0362196 2.01115L0.668049 2.42308L16.5 12.7656Z" fill="black"/>
                                        </svg>
                                    </a>
                                </LinkWrapper>
                            </SupportWrapper>
                        </AdditionalButtonsWrapper>
                    </>
                    )}
                </Sidebar>
                {isOpen && (<SidebarWrapper onClick={handleCloseSidebar}/>)}
            </Main>
        </Wrapper>
    )
}

export default ContentPage;