import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface IModulesState {
    modules: any;
}

const initialState: IModulesState = {
    modules: null
};

const modulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    setData: (state, action :PayloadAction<IModulesState>) => {
      state.modules = action.payload;
    }
  }
});

export const modulesActions = modulesSlice.actions;
export const modulesReducer = modulesSlice.reducer;