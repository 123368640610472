import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthorizationMutation, useRegistrationMutation } from "../../Store/APIs/auth.api";
import { ButtonsWrapper, Logo, PageWrapper, InputWrapper, TextWrapper, Text, Link, CloseIcon, Main, Gradient, FirstColor, SecondColor, LogoWrapper, MainButtonWrapper, ErrorMessage } from "./styled";
import { ButtonMainBig } from "../../Constants/ButtonMainBig";
import { ButtonSecondaryBig } from "../../Constants/ButtonSecondaryBig";
import HiddenEyeButton from "../../Constants/HiddenEye/HiddenEye";
import { Input } from "../../Constants/Input";
import logo from "../../Images/logo.png";
import CloseArrow from "../../Images/arrow.png";
import './styles.css';

export interface IUserData {
    username :string,
    password :string,
    email? :string,
}

declare global {
    interface Window {
      getUclick?: () => any;
    }
  }

const AuthPage = () => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const [isHidden, setIsHidden] = useState(true);

    useEffect(() => {
        if(localStorage.getItem('user_token')) {
            navigate('/contents');
        }
      setIsVisible(true);
    }, []);

    const [isOpenLogin, setOpenLogin] = useState(false);
    const [isOpenReg, setOpenReg] = useState(false);

    const [authorization] = useAuthorizationMutation();
    const [registration] = useRegistrationMutation();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [authError, setAuthError] = useState(false);
    const [regError, setRegError] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);

    const handleLogin = () => {
        setOpenReg(false);
        setOpenLogin(true);
        setAuthError(false);
    }

    const handleReg = () => {
        setOpenLogin(false);
        setAuthError(false);
        setOpenReg(true);
    }

    const handleClose = () => {
        setOpenLogin(false);
        setPassword('');
        setUsername('');
        setEmail('');
        setAuthError(false);
        setOpenReg(false);
    }

    const handleSubmitLogin = async () => {
        try {
            await authorization({username, password}).then((res: any) => {
                if (res.error) {                    
                    throw new Error(res.message);
                }
                navigate('/contents'); 
            });
        } catch (error) {           
            setAuthError(true);
        }
    };

    function lp_pixel_callback(t1: string) {
        const tracker_url='https://ad.academyblogger.site/';
        function lp_update_token(token: string,t1: string){
          var o = document.createElement("img");
          if (typeof window['getUclick'] == 'function'){
            o.src=tracker_url+'click.php?lp=data_upd&'+token+'='+t1+'&uclick='+window.getUclick();
          }else{
            o.src=tracker_url+'click.php?lp=data_upd&'+token+'='+t1;
          }
        }
        var url = window.location.href;

        lp_update_token('leadFromLandT',t1);
      }

    const handleSubmitReg= async () => {
    try {
        await registration({username, password, email}).then((res: any) => {
            if (res.error) {                    
                throw new Error(res.message);
            }
            localStorage.removeItem('is_pass');
            const t1 = localStorage.getItem('t1')

            if (t1) {
                lp_pixel_callback(t1)
            }
            navigate('/')
            window.location.reload();
        }
        );
    } catch (error) {
        setRegError(true);
    }
    };
    
    const handleChange = () => {
        setIsHidden(!isHidden)
    }

    return (
        <Main>
            <Gradient>
                <FirstColor/>
                <SecondColor/>
            </Gradient>
            <PageWrapper>
                {isOpenLogin || isOpenReg ? <CloseIcon onClick={handleClose} src={CloseArrow}></CloseIcon> : ''}
                <LogoWrapper className={`${isVisible ? "visible" : ''}`}>
                    <Logo log={isOpenLogin} reg={isOpenReg} src={logo}></Logo>
                </LogoWrapper>
                {isOpenLogin || isOpenReg ?
                <InputWrapper> 
                    <Input value={username} placeholder="Número de teléfono" onChange={(e) => {setUsername(e.target.value)
                    setAuthError(false);
                    setRegError(false);
                }
                }></Input>
                    <div style={{position: 'relative'}}> 
                        <Input value={password} type={isHidden ? "password" : 'text'} placeholder="Contraseña" onChange={(e) => {
                            setAuthError(false);
                            setPassword(e.target.value)}
                            }></Input>
                        <HiddenEyeButton isHidden={isHidden} handle={handleChange} />
                    </div>
                    {password.length >= 1 && isOpenReg && password.length <= 5 && <ErrorMessage>Las contraseñas deben tener al menos 6 caracteres, pero es mejor que sean de 8 o más</ErrorMessage>}
                    {authError && <ErrorMessage>Número de teléfono o contraseña incorrectos</ErrorMessage>}
                    {isOpenReg ?
                    <> 
                        <Input value={email} placeholder="Email" onChange={(e) => {
                            const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                            if (!(emailRegExp.test(e.target.value))) {
                                setIsEmailValid(false);
                            }
                            else {
                                setIsEmailValid(true)
                            }
                            setEmail(e.target.value);
                            setRegError(false)
                          }
                        }>
                        </Input>
                        {!isEmailValid && <ErrorMessage>El correo electrónico debe estar en formato example@example.com</ErrorMessage>}
                        {regError && <ErrorMessage>El correo electrónico o el número de teléfono ya están ocupados</ErrorMessage>}
                    </>
                    : ''}
                </InputWrapper>
                : ''}
                <ButtonsWrapper className={`${isVisible ? "visible" : ''}`}>
                    {!isOpenLogin && !isOpenReg ? 
                    <MainButtonWrapper className={`${isVisible ? "visible" : ''}`}>
                        <ButtonMainBig onClick={handleLogin}>Login</ButtonMainBig>
                        <ButtonSecondaryBig onClick={handleReg}>Registro</ButtonSecondaryBig>
                    </MainButtonWrapper>
                    :
                    ''
                    }

                    {isOpenLogin ?
                    <> 
                        <ButtonMainBig disabled={username.length === 0 || username.length > 1000 || password.length < 0 ||  password.length > 1000} onClick={handleSubmitLogin}>Login</ButtonMainBig>
                        <Text>¿No tienes una cuenta?</Text>
                        <Link onClick={handleReg}>Registro</Link>
                    </> 
                    : ''}
                    {isOpenReg ?
                    <> 
                        <ButtonMainBig disabled={!isEmailValid || username.length === 0 || username.length > 1000 || password.length < 0 || password.length < 6 || password.length > 1000 || email.length === 0 || email.length > 1000} onClick={handleSubmitReg}>Registro</ButtonMainBig>
                        <TextWrapper>
                            <Text>¿Tener una cuenta?</Text>
                            <Link onClick={handleLogin}>Login</Link>
                        </TextWrapper>
                    </> 
                    : ''}
                </ButtonsWrapper>
            </PageWrapper>
        </Main>
    );
}

export default AuthPage;