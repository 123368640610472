import { useEffect } from 'react';
import { useAppSelector } from '../../Hooks/redux/useAppSelector';
import { useLocation, useNavigate } from 'react-router';
import { Application } from './styled';
import ContentProvider from '../ContentProvider';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const {errors} = useAppSelector(state => state.errorsSlice);
  const {isAuthorization} = useAppSelector(state => state.authSlice);
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get('t1');
  
  if (paramValue) {
    localStorage.setItem('t1', paramValue)
  }

  if (errors.length > 0) {
     console.log(errors);
  }
  
   useEffect(() => {
    if (!isAuthorization) {
       return navigate('/authorization');
     }
  }, [isAuthorization, navigate]);

  return (
  <Application>
    <ContentProvider/>
  </Application>
  );
}

export default App;
