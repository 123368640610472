import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {errorsActions} from '../Slices/errors.slice';
import { modulesActions } from '../Slices/modules.slice';

interface IUserData {
  populate :string
}

interface IModulesResponse {
  data: any;
  modules :any;
}

const {addError} = errorsActions;
const {setData} = modulesActions;

export const ModulesApi = createApi({
  reducerPath: 'ModulesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.academyblogger.site',
    prepareHeaders: headers => {
        const token :string | null = localStorage.getItem('user_token');
  
        if (token) headers.set('Authorization', `Bearer ${token}`);
      }
  }),
  endpoints: (build) => ({
    modules: build.query<IModulesResponse, IUserData>({
      query: () => ({
        url: 'api/modules',
        method: 'get',
        params: {
            "populate[lessons][populate]": "*"
        }
      }),
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          const response = await queryFulfilled;
          if (!response) {
            throw new Error(response);
          }
        
          dispatch(setData(response.data));
        } catch (error :any) {
          localStorage.removeItem('user_token');
          localStorage.removeItem('user_id');
          dispatch(addError(error.error));
        }
      },
    })
  })
});

export const {useModulesQuery} = ModulesApi;