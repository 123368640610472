import styled from "styled-components";

export const ButtonMainBig = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 146.5px;
    border-radius: 12px;
    border: 1px solid #7E57C2;
    background-color: #7E57C2;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 16.41px;
    font-family: "Roboto";
    font-weight: 400; 
    width: 328px;
    text-align: center;
    cursor: pointer;
    z-index: 1;

    &:disabled {
        cursor: not-allowed;
        background: #616161;
        color: #BDBDBD
    }
`