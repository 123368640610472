import styled from "styled-components";

export const ButtonMainMedium = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 31.5px;
    border-radius: 12px;
    border: 1px solid #7E57C2;
    background-color: #7E57C2;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 16.41px;
    font-family: "Roboto";
    font-weight: 400; 
    width: 122px;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    z-index: 1;
`