import styled from "styled-components";

export const Input = styled.input`
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #7E57C2;
    background-color: transparent;
    outline: none;
    width: 320px;
    height: 33px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: "Roboto";
    z-index: 1;
`