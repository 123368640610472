import styled from "styled-components";

interface IProps {
    log :boolean,
    reg :boolean
  }

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: translateY(300%);
    transition: opacity 1s ease-out, transform 0.8s ease-out;
`
export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
`
export const ErrorMessage = styled.p`
    width: 324px;
    color: #CD0000;
    font-size: 12px;
    margin: 0;
`

export const MainButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    opacity: 0;
    transform: translateY(300%);
    transition: opacity 1s ease-out, transform 0.8s ease-out;
`
export const Logo = styled.img<IProps>`
    width: ${props => props.log || props.reg ? '153px' : '264px'};
    height: ${props => props.log || props.reg ? '89px' : '155px'};
`
export const LogoWrapper = styled.div`
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 0.5s ease-out, transform 0.8s ease-out;
`

export const PageWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 5vh 16px;
    gap: 10vh;
    height: 80vh;
`

export const Link = styled.a`
    cursor: pointer;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #050505;
`

export const Text = styled.p`
    margin: 0;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #050505;
`
export const TextWrapper = styled.div`
    text-align: center;
`

export const CloseIcon = styled.img`
  padding: 10px;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
`
export const Main = styled.div`
  overflow: hidden;
  height: 100vh;
`
export const Gradient = styled.div`
    position: absolute;
    width: 100vw;
    height: 100%;
    z-index: 0;
    overflow: hidden;
`
export const FirstColor = styled.div`
    width: 120vw;
    height: 80vh;
    flex-shrink: 0;
    border-radius: 600px;
    opacity: 0.5;
    background: radial-gradient(57.14% 57.14% at 50% 50%, rgba(249, 217, 118, 0.66) 0%, rgba(243, 159, 134, 0.46) 48.28%, rgba(243, 159, 134, 0.08) 100%);
    mix-blend-mode: darken;
    filter: blur(10px);
`
export const SecondColor = styled.div`
    position: absolute;
    left: -20vw;
    top: 70vh;
    width: 120vw;
    height: 100vh;
    flex-shrink: 0;
    border-radius: 558px;
    opacity: 0.5;
    background: radial-gradient(57.14% 57.14% at 50% 50%, rgba(168, 139, 235, 0.66) 0%, rgba(232, 153, 220, 0.46) 48.28%, rgba(240, 208, 238, 0.08) 100%);
    mix-blend-mode: darken;
    filter: blur(10px);
`