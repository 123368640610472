import styled from "styled-components";

interface IProps {
    open: boolean
  }

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    jusify-content: center;
    align-items: center;
`
export const Logo = styled.img`
    height: 15vh;
`
export const PageWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
`
export const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 27px 16px;
    gap: 3vh;
    padding: 0 24px;
`
export const TextWrapper = styled.div`
    text-align: center;
`

export const Title = styled.h1`
    font-family: "Roboto";
    font-size: 32px;
    line-height: 42px;
    font-weight: 300;
    color: #7E57C2;
`
export const Text = styled.p`
    font-family: "Roboto";
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #050505;
`

export const ProgressBarWrapper = styled.div`
    display: flex;
    gap: 12px;
`
export const ProgressBar = styled.div<IProps>`
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: ${props => props.open ? '#7E57C2' : '#BDBDBD'};
`

export const ButtonsWrapperSmall = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const ButtonSkip = styled.a`
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: #616161;
  cursor: pointer;
`
export const Gradient = styled.div`
    position: absolute;
    width: 100vw;
    height: 100%;
    z-index: 0;
    overflow: hidden;
`
export const FirstColor = styled.div`
    width: 120vw;
    height: 80vh;
    flex-shrink: 0;
    border-radius: 600px;
    opacity: 0.5;
    background: radial-gradient(57.14% 57.14% at 50% 50%, rgba(249, 217, 118, 0.66) 0%, rgba(243, 159, 134, 0.46) 48.28%, rgba(243, 159, 134, 0.08) 100%);
    mix-blend-mode: darken;
    filter: blur(10px);
`
export const SecondColor = styled.div`
    position: absolute;
    left: -20vw;
    top: 70vh;
    width: 120vw;
    height: 100vh;
    flex-shrink: 0;
    border-radius: 558px;
    opacity: 0.5;
    background: radial-gradient(57.14% 57.14% at 50% 50%, rgba(168, 139, 235, 0.66) 0%, rgba(232, 153, 220, 0.46) 48.28%, rgba(240, 208, 238, 0.08) 100%);
    mix-blend-mode: darken;
    filter: blur(10px);
`