import { DescriptionModuleText, DescriptionModuleTitle, DescriptionModuleWrapper } from "./styled";

interface IProps {
    actual: number,
    dataUser: any
}

const DescriptionModules = ({actual, dataUser}:IProps) => {
    
    return (
    <DescriptionModuleWrapper>
        <DescriptionModuleTitle>{actual ? dataUser.data[actual-1].attributes.name : ''}</DescriptionModuleTitle>
        <DescriptionModuleText>{actual ? dataUser.data[actual-1].attributes.description : ''}</DescriptionModuleText>
    </DescriptionModuleWrapper>
    )
}

export default DescriptionModules;
