import { useEffect, useState } from "react";
import { LessonsWrapper, LessonContentWrapper, Lesson, LessonTitle, LessonDescription, IconWrapper, IconPic, IconNum, VerticalLine, PopupLesson } from "./styled"
import { ButtonMainMedium } from "../../../Constants/ButtonMainMedium";
import HourGlass from "../../../Images/hourglass.svg";
import Book from "../../../Images/book.svg";
import ErrorBook from "../../../Images/errorbook.svg";
import SuccessBook from "../../../Images/sucessbook.svg";
import { TailSpin } from "react-loader-spinner";

interface IProps {
    loading: any,
    dataUser: any,
    passes: number[],
    module: number,
    ispopup: boolean,
    setpopup: any,
    choselesson: any,
    setlesson: any,
    homeworkstatus: string,
}

const LessonsList = ({loading, dataUser, passes, module, ispopup, setpopup, choselesson, setlesson, homeworkstatus}: IProps) => {
    const [popup, setPopup] = useState(-1);
    const [actualLesson, setActualLesson] = useState({id: -1});
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(true)
        }, 200)
    }, [])

    let lessonCount = 0;
    
    useEffect(() => {
        
        if (dataUser && module && passes) {
            
            let result = dataUser.data[module-1].attributes.lessons.data.find((item: any, index: number) => !passes.includes(item.id));
            if (result === undefined) {
                result = {id: 0};
            }

            setActualLesson(result);
            setlesson(result);
            loading(false);
            setIsLoading(false);
        }
    }, [dataUser, module, passes, homeworkstatus])
    
    const handleChangePopup = (lesson: any) => {
        setpopup(true)
        setPopup(lesson)
    }

    const handleChooseLesson = (index: any) => {
        choselesson(index)
    }
    
    return(
        <LessonsWrapper>
            {actualLesson.id > -1 && dataUser && module && passes ? dataUser.data[module-1].attributes.lessons.data.map((item: any, index: number) => {
            const isPass = passes.includes(item.id)
            let elem;
            
            switch (item.attributes.type) {
                case 'lesson':                    
                    break;
                case 'input':
                    break;
                case 'input-test':
                    if (homeworkstatus === 'wait' && !isPass) {
                        elem = <IconPic src={HourGlass}></IconPic>
                    }
                    else if (homeworkstatus === 'error' && !isPass) {
                        elem = <IconPic src={ErrorBook}></IconPic>
                    }
                    else if (isPass) {
                        elem = <IconPic src={SuccessBook}></IconPic>
                    }
                    else {
                        elem = <IconPic src={Book}></IconPic>
                    }
                    break;
                default:
                    break;
            }

            return (
                <Lesson key={index} onClick={() => handleChangePopup(index)}>
                    <IconWrapper type={item.attributes.type} ispass={isPass}>
                        {elem ? elem : <IconNum ispass={isPass}>{++lessonCount}</IconNum>}
                    </IconWrapper>
                    {popup === index && ispopup ?
                    <PopupLesson>
                        <LessonTitle>{item.attributes.name}</LessonTitle>
                        <LessonDescription>{item.attributes.description}</LessonDescription>
                        {isPass || item.id === actualLesson.id ? <ButtonMainMedium disabled={!(isPass) && item.id !== actualLesson.id} onClick={() => handleChooseLesson(dataUser.data[module-1].attributes.lessons.data[index])}>Comenzar</ButtonMainMedium> : ''}
                    </PopupLesson>
                        :
                    <LessonContentWrapper>
                        <LessonTitle>{item.attributes.name}</LessonTitle>
                        <LessonDescription>{item.attributes.description && item.attributes.description.length>30 ? `${item.attributes.description.slice(0, 30)}...` : item.attributes.description}</LessonDescription>
                    </LessonContentWrapper>
                }
                    {index+1 === dataUser.data[module-1].attributes.lessons.data.length ? '' : <VerticalLine ispass={isPass}/>}
                </Lesson>
            )
            }
            ):
            <>
                {isLoading ?
                <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10}}>
                    <TailSpin visible={true}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""/>
                </div>
                :
                ''
                }
            </>             
           }
        </LessonsWrapper>
    )
}

export default LessonsList;