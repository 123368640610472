import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface IPassesState {
    passes: any;
}

const initialState: IPassesState = {
    passes: null
};

const passesLessonsSlice = createSlice({
  name: 'passes',
  initialState,
  reducers: {
    setData: (state, action :PayloadAction<IPassesState>) => {
      state.passes = action.payload;
    }
  }
});

export const passesActions = passesLessonsSlice.actions;
export const passesReducer = passesLessonsSlice.reducer;