import styled from 'styled-components';

export const HiddenButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;

  width: 20px;
  height: 20px;
  object-fit: contain;

  cursor: pointer;

  color: #A7A7A7;

  &:hover {
    color: #030C1CCC;
  }
`;