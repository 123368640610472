import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Containers/App';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import { setupStore } from './Store/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const store = setupStore();
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
