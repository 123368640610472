import styled from "styled-components";

export const SidebarModulesWrapper = styled.div`
    padding-top: 70px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
`
export const IconMarker = styled.img`
    position: absolute;
    top: -15px;
    right: -15px;
`