import { useEffect, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonsWrapper, Logo, PageWrapper, ContentWrapper, TextWrapper, Title, Text, ProgressBarWrapper, ProgressBar, ButtonsWrapperSmall, ButtonSkip, Gradient, FirstColor, SecondColor} from "./styled";
import { ButtonMainBig } from "../../Constants/ButtonMainBig";
import { ButtonSecondaryBig } from "../../Constants/ButtonSecondaryBig";
import { ButtonMainSmall } from "../../Constants/ButtonMainSmall";
import logo from "../../Images/logo.png";

const OnboardingPage = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [title, setTitle] = useState('¡Hola!');
    const [text, setText] = useState('¡Me alegra verte en nuestra academia!\nAhora te explicamos rápidamente qué aprenderás al estudiar con nosotros, a qué te dedicarás y, por supuesto, cómo convertirte en un bloguero exitoso.')

    const handleSetStep = () => {
        setStep(step+1);
    }

    const handleSkip = () => {
        localStorage.setItem('is_pass', '1');
        navigate('/contents')
    }

    useEffect(() => {
        if(localStorage.getItem('is_pass') === '1') {
            handleSkip();
        }

        switch (step) {
            case 2:
                setTitle('Módulos');
                setText('Te esperarán 8 módulos que constan de varias lecciones, actividades prácticas y tareas para casa.');
                break;
            
            case 3:
                setTitle('Lecciones');
                setText('Cada lección está concebida para que te resulte lo más fácil e interesante posible asimilar el material didáctico.');
                break;
    
            case 4:
                setTitle('Práctica');
                setText('Al finalizar las lecciones, deberás consolidar los conocimientos adquiridos.\nLa práctica te ayudará a entender mejor el contenido, la competencia, la audiencia y mucho más.');
                break;
    
            case 5:
                setTitle('Tareas');
                setText('Además, está previsto el trabajo independiente y creativo que te permitirá desarrollar todos tus talentos y convertirte en el mejor y más exitoso bloguero.');
                break;
    
            case 6:
                setTitle('Monetización');
                setText('Al finalizar el curso, tendrás acceso a la base de datos de anunciantes que están dispuestos a pagarte por la publicidad');
                break;
    
            case 7:
                setTitle('Adelante!');
                setText('Igualmente, no debes preocuparte si no entiendes algo o no lo consigues. Nuestro equipo de soporte te ayudará siempre que lo necesites (haz clic en el botón correspondiente del menú).\n¡Y ahora que ya sabes lo que tienes que hacer, es hora de empezar!');
                break;
        }
    }, [step])

    return (
        <PageWrapper>
            <Gradient>
                    <FirstColor/>
                    <SecondColor/>
            </Gradient>
            <ContentWrapper>
                <Logo src={logo}></Logo>
                <TextWrapper>
                    <Title>{title}</Title>
                    <Text>{text.split('\n').map((line, index) => (<React.Fragment key={index}>{line}<br/><br/></React.Fragment>))}</Text>
                </TextWrapper>
                {step > 1 && (
                <ProgressBarWrapper>
                    {Array(7).fill(null).map((_, index) => (
                    <ProgressBar open={index < step} key={index} />
                    ))}
                </ProgressBarWrapper>
                )}
                {step > 1 ? 
                <ButtonsWrapperSmall>
                    <ButtonSkip onClick={handleSkip}>Saltar</ButtonSkip>
                    <ButtonMainSmall onClick={step === 7 ? handleSkip : handleSetStep}>Siguiente</ButtonMainSmall>
                </ButtonsWrapperSmall> 
                
                :

                <ButtonsWrapper>
                    <ButtonMainBig onClick={handleSetStep}>Siguiente</ButtonMainBig>
                    <ButtonSecondaryBig onClick={handleSkip}>Saltar</ButtonSecondaryBig>
                </ButtonsWrapper>
            }
            
            </ContentWrapper>
        </PageWrapper>
    );
}

export default OnboardingPage;