import styled from "styled-components";

interface IProps {
    ispass: boolean
}

interface IType {
    type: string
}

export const LessonsWrapper = styled.div`
    padding-top: 33px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 17px;
`

export const Lesson = styled.div`
    position: relative;
    display: flex;
    gap: 24px;
    padding: 0 15px;
    align-items: start;
`
export const VerticalLine = styled.div<IProps>`
    position: absolute;
    width: 0;
    height: 24px;

    left: 43px;
    top: calc((100% + 70px) / 2);
  
    border-left: ${props => props.ispass ? '2px solid #FFFFFF' : '2px solid #BDBDBD'};
    border-right: none;
    border-top: none;
    border-bottom: none;
`
export const IconWrapper = styled.div<IProps & IType>`
    position: relative;
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background-color: ${props => props.ispass && props.type !== 'input-test' ? '#00C853' : '#FFF'};
    flex: 0 0 auto;
`
export const IconPic = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    stroke: red;
`
export const IconNum = styled.p<IProps>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Roboto";
    font-weight: 600;
    font-size: 24px;
    line-height: 30.86px;
    color: ${props => props.ispass ? '#FFFFFF' : '#616161'};
    margin: 0;
`
export const LessonContentWrapper = styled.div`
    padding-right: 30px;
`
export const LessonTitle = styled.h3`
    margin: 0;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 400;
    line-height: 25.2px;
    color: #050505;
    
`
export const LessonDescription = styled.p`
    margin: 0;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #050505;
`
export const PopupLesson = styled.div`
    gap: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 21px;

    border-radius: 8px;

    padding-top: 16px;
    padding-right: 17px;
    padding-bottom: 16px;
    padding-left: 36px;

    background-color: #FFFFFF;
    box-shadow: rgb(224, 224, 224) 0px 0px 14px 0px;
`