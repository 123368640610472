import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {errorsActions} from '../Slices/errors.slice';
import {authActions} from '../Slices/auth.slice';

interface IUserAuthorizationData {
  username :string,
  password :string,
  email? :string
}

interface IAuthorizationResponse {
  jwt :string;
  error: any
}

const saveToken = (token :string) => {
  localStorage.setItem('user_token', token);
};
const saveID = (data :any) => {
  localStorage.setItem('user_id', data.user.id);
};

const {addError} = errorsActions;
const {setAuthenticated} = authActions;

export const AuthApi = createApi({
  reducerPath: 'AuthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.academyblogger.site'
  }),
  endpoints: (build) => ({
    authorization: build.mutation<IAuthorizationResponse, IUserAuthorizationData>({
      query: ({username, password}) => ({
        url: 'api/auth/local',
        method: 'post',
        body: {
          identifier: username,
          password: password
        }
      }),
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          const response = await queryFulfilled;
          if ('data' in response) {
            saveToken(response.data.jwt);
            saveID(response.data)
          }
          dispatch(setAuthenticated(true));
        } catch (error :any) {
          dispatch(addError(error.error));
        }
      },
    }),
    registration: build.mutation<IAuthorizationResponse, IUserAuthorizationData>({
      query: ({username, password, email}) => ({
        url: 'api/auth/local/register',
        method: 'post',
        body: {
          username: username,
          email: email,
          password: password
        }
      }),
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          const response = await queryFulfilled;
          if ('data' in response) {
            saveToken(response.data.jwt);
            saveID(response.data)
          }
          dispatch(setAuthenticated(true));
        } catch (error :any) {
          dispatch(addError(error.error));
        }
      },
    })
  })
});

export const {useAuthorizationMutation, useRegistrationMutation} = AuthApi;