import styled, { keyframes } from "styled-components";

interface IProps {
    select: {
        isChose: boolean
    }
}

interface ICorrectChose {
    iscorrectchose: boolean
}

interface IChose {
    ischose: boolean
}

interface ICorrect {
    iscorrect: boolean
}

interface IHomeWorkStatus {
    homeworkstatus: string
}

interface ILessonType {
    lessontype: string
}

interface IIsError {
    iserror: any
}

interface ISlidePercentage {
    slideScroll: number
}

interface ISlidePrevPercentage {
    prevSlide: number
}


export const Header = styled.div`
    position: fixed;
    z-index: 2;
    padding-left: 17px;

    display: flex;
    align-items: center;
    gap: 34px;

    background-color: #FFFFFF;

    height: 56px;
    width: 100%;
`
export const HeaderText = styled.h3`
    margin: 0;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 400;
    line-height: 25.2px;
    color: #050505;
`
export const HeaderClose = styled.img`
    pointer: cursor;
`

// PROGRESS BAR
export const ProgressBar = styled.div`
    position: fixed;
    top: 56px;
    left: 0;
    width: 100%;
    display: flex;
    height: 4px;
    box-shadow: 0px 3px 4px 0px rgba(97, 97, 97, 0.40);
    z-index: 2;
`
export const fillSlideAnimation = keyframes<ISlidePercentage & ISlidePrevPercentage>`
        from { width: ${props => props.prevSlide}%; }
        to { width: 100%;}
`;

export const ProgressBarWrapperSlide = styled.div`
    position: relative;
    flex-grow: 1;
    background-color: #EDE7F6;
    position: relative;
    overflow: hidden; 
`

export const ProgressBarSlide = styled.div<ISlidePercentage & ISlidePrevPercentage>`
    background-color: #EDE7F6;
    position: absolute;
    overflow: hidden; 
    &.fillSlide {
        position: absolute;
        top: 0;
        left: 0;
        width: ${props => props.slideScroll}%;
        height: 100%;
        background-color: #7E57C2;
        z-index: 1;
        animation: ${fillSlideAnimation} 0.2s linear forwards;
    }
`
//


export const LessonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
`
export const LessonContent = styled.div<IHomeWorkStatus & ILessonType>`
    width: 95%;
    padding-top: 96px;
    padding-left: 20px;
    padding-right: 16px;
    padding-bottom: ${props => props.homeworkstatus && props.lessontype === 'input-test' ? '0' : '92px'};
    z-index: 1;
`
export const TestWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 32px;
`
export const TestElement = styled.button<IProps>`
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 28px;
    border-radius: 12px;
    border: ${props => props.select.isChose ? '1px solid #00C853' : '1px solid #7E57C2'};
    background-color: ${props => props.select.isChose ? '#00C853' : 'rgba(0, 0, 0, 0)'};
    color: ${props => props.select.isChose ? '#FFFFFF' : '#050505'};
    font-size: 16px;
    line-height: 24px;
    font-family: "Roboto";
    font-weight: 400; 
    width: 100%;
    height: 40px;
    outline: none;
    text-align: left;
    cursor: pointer;
`
export const TestElementPass = styled.button<ICorrect & IChose & ICorrectChose>`
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 28px;
    border-radius: 12px;
    border: ${props => !props.iscorrectchose && props.iscorrect && !props.ischose? '1px solid #00C853' : (props.iscorrectchose && !props.iscorrect ? '1px solid #7E57C2' : 'none')};
    background-color: ${props => props.iscorrectchose && props.iscorrect ? '#00C853' : (props.ischose ? "#E64A19" : 'rgba(0, 0, 0, 0)')};
    color: ${props => props.ischose ? '#FFFFFF' : '#050505'};
    font-size: 16px;
    line-height: 24px;
    font-family: "Roboto";
    font-weight: 400; 
    width: 100%;
    height: 40px;
    outline: none;
    text-align: left;
    cursor: pointer;
`
export const HomeworkTitle = styled.h2`
    margin: 0;
    font-family: "Roboto";
    font-size: 24px;
    font-weight: 400;
    line-height: 30.24px;
    color: #050505;
`
export const HomeworkElem = styled.div`
    padding-bottom: 47px;
`
export const HomeworkDescription = styled.p`
    margin: 0;
    padding-top: 24px;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #050505;
`
export const Input = styled.input<IIsError>`
    margin-top: 24px;
    width: 100%;
    box-sizing: border-box;
    height: 44px;
    outline: none;
    border: ${props => props.iserror && (props.iserror.length<20 || props.iserror.length>1000) ? '1px solid #C25757' : '1px solid #BDBDBD'} !important;
    border-radius: 4px;

    &:focus {
    border: 1px solid #7E57C2;
    box-shadow: 0px 3px 4px rgba(97, 97, 97, 0.4);
  }
`
export const InputErrorText = styled.p`
  margin: 0;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 700;
  line-height: 126%;
  color: #FF7070;
`
export const HomeWorkSended = styled.div`
    padding: 0 14px;
    padding-bottom: 34px;
    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
`
export const IconSendedHomeWork = styled.img`
`
export const TextSendedHomeWork = styled.p`
  margin: 0;
  text-align: center;
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
`
export const TextComment = styled.p`
    margin: 0;
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
`