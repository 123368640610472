import { Route, Routes } from 'react-router';
import {JSX} from 'react';
import OnboardingPage from '../../Pages/OnboardingPage';
import ContentPage from '../../Pages/ContentPage';
import SupportPage from '../../Pages/SupportPage';
import AuthPage from '../../Pages/AuthPage';


interface IRoutes {
  path: string,
  element: JSX.Element
}

const ContentProvider = () => {
  const ROUTES: IRoutes[] = [
    {
      path: '/',
      element: <OnboardingPage/>
    },
    {
      path: '/contents',
      element: <ContentPage/>
    },
    {
      path: '/supports',
      element: <SupportPage/>
    },
    {
      path: '/authorization',
      element: <AuthPage/>
    }
  ];

  return (
    <Routes>
        {ROUTES.map(({path, element}) => <Route key={path} path={path} element={element}/>)}
    </Routes>
  );
};

export default ContentProvider;