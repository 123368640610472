import { SidebarModulesWrapper, IconMarker } from "./styled"
import { ButtonMainMedium } from "../../../Constants/ButtonMainMedium"
import { ButtonSecondaryMedium } from "../../../Constants/ButtonSecondaryMedium"
import ExclamationMarkError from "../../../Images/exclamationmark.svg";
import SuccessIcon from "../../../Images/checkmarksuccess.svg";

interface IProps {
    choseModule: any,
    dataUser: any,
    last: number,
    errorlessonid: number | null,
    passeslesson: any,
    isfinished: boolean 
}


const SidebarModules = ({dataUser, last, choseModule, errorlessonid, passeslesson, isfinished}: IProps) => {

    const handleChange = (index: number) => {
        choseModule(index);
    };

    const redirectToTelegramProfile = () => {
        window.location.href = "https://t.me/Sukharto";
    }

    return (
        <SidebarModulesWrapper>
        {dataUser.data.map((item: any, index: number) => {                        
            const isError = item.attributes.lessons.data.filter((item: any) => item.id === errorlessonid);
            const isHomeWork = item.attributes.lessons.data.filter((item: any) => item.attributes.type === 'input-test');
            let isPass = false;
            
            if (isHomeWork.length > 0) {
                passeslesson.data.map((item: any) => {
                    if (item.attributes.lesson.data.id === isHomeWork[0].id) {
                        isPass = true;
                    }
                })
            }

            if (isError.length>0 && !isPass) {
                return (
                    <ButtonMainMedium style={{position: 'relative'}} onClick={() => handleChange(index+1)} value={index+1} key={index}>
                        Módulo {index+1}
                        <IconMarker src={ExclamationMarkError}></IconMarker>
                    </ButtonMainMedium>
                )
            }
            else if (isHomeWork.length>0 && isPass) {
                return (
                    <ButtonMainMedium style={{position: 'relative'}} onClick={() => handleChange(index+1)} value={index+1} key={index}>
                        Módulo {index+1}
                        <IconMarker style={{width: 34, height: 34}}  src={SuccessIcon}></IconMarker>
                    </ButtonMainMedium>
                )
            }
            else {
                return (
                    index<=last-1 ?
                    <ButtonMainMedium onClick={() => handleChange(index+1)} value={index+1} key={index}>Módulo {index+1}</ButtonMainMedium> 
                    :
                    <ButtonSecondaryMedium disabled={true} key={index}>Módulo {index+1}</ButtonSecondaryMedium>
                )
            }
        }
        )}
            {isfinished ? 
            <ButtonMainMedium style={{whiteSpace: 'pre-wrap', padding: '9px 10px'}} onClick={redirectToTelegramProfile}  key={dataUser.data.length}>Encontrar un anunciante</ButtonMainMedium> 
            :
            <ButtonSecondaryMedium style={{whiteSpace: 'pre-wrap', padding: '9px 10px'}} disabled={true} key={dataUser.data.length}>Encontrar un anunciante</ButtonSecondaryMedium>
            }
        </SidebarModulesWrapper>
    )
}

export default SidebarModules;