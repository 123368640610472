import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {errorsActions} from '../Slices/errors.slice';
import { passesActions } from '../Slices/lessonsPasses.slice';

interface IPassesResponse {
  passes :any;
}

const {addError} = errorsActions;
const {setData} = passesActions;

export const PassLessonsApi = createApi({
  reducerPath: 'PassesLessonsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.academyblogger.site',
    prepareHeaders: headers => {
        const token :string | null = localStorage.getItem('user_token');
  
        if (token) headers.set('Authorization', `Bearer ${token}`);
      }
  }),
  endpoints: (build) => ({
    passes: build.query<IPassesResponse, void>({
      query: () => ({
        url: 'api/lessons-passes',
        method: 'get',
        params: {
            populate: 'users_permissions_users, lesson',
            fields: "id",
            "filters[pass][$eq]": true,
            "filters[users_permissions_users][id][$eq]": localStorage.getItem('user_id')
        }
      }),
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          const response = await queryFulfilled;
          if (!response) {
            throw new Error(response);
          }
          dispatch(setData(response.data));
        } catch (error :any) {
          localStorage.removeItem('user_token');
          localStorage.removeItem('user_id');
          dispatch(addError(error.error));
        }
      },
    })
  })
});

export const {usePassesQuery} = PassLessonsApi;