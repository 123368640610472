import styled from "styled-components";

interface IProps {
    open: boolean
}

export const Wrapper = styled.div`
    min-height: 100vh;
`
export const Main = styled.div`
    z-index: 1;
`
export const Gradient = styled.div`
    position: absolute;
    width: 100vw;
    height: 100%;
    z-index: 0;
    overflow: hidden;
`
export const FirstColor = styled.div`
    width: 120vw;
    height: 80vh;
    flex-shrink: 0;
    border-radius: 600px;
    opacity: 0.5;
    background: radial-gradient(57.14% 57.14% at 50% 50%, rgba(249, 217, 118, 0.66) 0%, rgba(243, 159, 134, 0.46) 48.28%, rgba(243, 159, 134, 0.08) 100%);
    mix-blend-mode: darken;
    filter: blur(10px);
`
export const SecondColor = styled.div`
    position: absolute;
    left: -20vw;
    top: 70vh;
    width: 120vw;
    height: 100vh;
    flex-shrink: 0;
    border-radius: 558px;
    opacity: 0.5;
    background: radial-gradient(57.14% 57.14% at 50% 50%, rgba(168, 139, 235, 0.66) 0%, rgba(232, 153, 220, 0.46) 48.28%, rgba(240, 208, 238, 0.08) 100%);
    mix-blend-mode: darken;
    filter: blur(10px);
`
export const PageWrapper = styled.div<IProps>`
    filter:  ${props => props.open ? 'blur(2px)' : ''};
`
export const HeaderWrapper = styled.div`
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 56px;
    background-color: #7E57C2;
    display: flex;
    align-items: center;
    padding-left: 17px;
    gap: 30px;
`
export const SidebarWrapper = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 0;
`
export const Sidebar = styled.div`
    z-index: 1;
    position: fixed;
    left: 0;
    top: 0;
    width: 162px;
    height: 100%;
    background-color: #FFFFFF;
    overflow-x: hidden;
    overflow-y: scroll;
`
export const CloseSideBar = styled.img`
    cursor: pointer;
    padding-top: 32px;
    padding-left: 20px;
`
export const SidebarIcon = styled.img`
    height: 16.8px;
    cursor: pointer;
`
export const Title = styled.h1`
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 400;
    line-height: 25.2px;
    color: #FFFFFF;
`
export const AdditionalButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 9px;
`
export const SupportButton = styled.button`
    border: none;
    background: none;
    padding: 0;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    line-height: 20.58px;
    color: #050505;
    cursor: pointer;
`
export const SupportWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
`
export const LinkWrapper = styled.div`
    display:flex;
    gap: 14px;
`